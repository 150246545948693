// src/components/Services.js

import React from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';

const Services = () => {
  return (
    <Container className="mt-5">
      <h2 className="text-center mb-4" id='services'>Our Properties</h2>
      <Row>
        <Col md={6}>
          <Card>
            <Card.Img variant="top" src="images/build1.jpg" />
            <Card.Body>
              <Card.Title>Property 1</Card.Title>
              <Card.Text>
                This is a brief description of the first property. It’s a beautiful place with all the modern amenities and a great location.
              </Card.Text>
              <Button variant="primary">View Property 1</Button>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card>
            <Card.Img variant="top" src="images/build2.jpg" />
            <Card.Body>
              <Card.Title>Property 2</Card.Title>
              <Card.Text>
                This is a brief description of the second property. It's a wonderful property with amazing features and scenic surroundings.
              </Card.Text>
              <Button variant="primary">View Property 2</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Services;
