// src/components/WhatsAppChat.js

import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';  // FontAwesome WhatsApp icon
// import './WhatsAppChat.css';  // Optional: For additional styles (you can keep styles inline if preferred)

const WhatsAppChat = () => {
  // const whatsappNumber = 'YOUR_PHONE_NUMBER'; // Replace with your phone number in international format, no spaces or +
  // const message = 'Hello, I am interested in your property!'; // Default message (optional)

  const handleWhatsAppClick = () => {
    window.open(`https://wa.link/cg7wf9`, '_blank');
  };

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#25D366',  // WhatsApp green color
        borderRadius: '50%',
        padding: '15px',
        cursor: 'pointer',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
      }}
      onClick={handleWhatsAppClick}
      title="Chat with us on WhatsApp"
    >
      <FaWhatsapp color="#fff" size="30px" />
    </div>
  );
};

export default WhatsAppChat;
