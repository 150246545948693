import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import MyNavbar from './components/Navbar';
import MyCarousel from './components/Carousel';
import Services from './components/Services';
import Contact from './components/Contact';
import About from './components/About';
import WhatsAppChat from './components/WhatsAppChat';

function App() {
  return (
    <div>
      <MyNavbar/>
      <div className="container mt-4">
        <MyCarousel/>
        <Services/>
        <Contact/>
        <About/>
        <WhatsAppChat/>
      </div>
    </div>
  );
}

export default App;