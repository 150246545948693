// src/components/Contact.js

import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaInstagram } from 'react-icons/fa';

const Contact = () => {
  return (
    <Container className="mt-5" id='contact'>
      <h2 className="text-center mb-4">Get in Touch</h2>
      <Row className="gy-4"> {/* Bootstrap's grid system with gutter for responsive spacing */}
        <Col md={6} lg={3}>
          <Card className="text-center h-100">
            <Card.Body>
              <FaPhone size={40} className="mb-3" style={{ color: '#007bff' }} />
              <Card.Title>Phone</Card.Title>
              <Card.Text>
                <a href="tel:+123456789" style={{ textDecoration: 'none', color: 'inherit' }}>
                  +123 456 789
                </a>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6} lg={3}>
          <Card className="text-center h-100">
            <Card.Body>
              <FaEnvelope size={40} className="mb-3" style={{ color: '#28a745' }} />
              <Card.Title>Email</Card.Title>
              <Card.Text>
                <a href="mailto:email@example.com" style={{ textDecoration: 'none', color: 'inherit' }}>
                  email@example.com
                </a>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6} lg={3}>
          <Card className="text-center h-100">
            <Card.Body>
              <FaMapMarkerAlt size={40} className="mb-3" style={{ color: '#dc3545' }} />
              <Card.Title>Address</Card.Title>
              <Card.Text>
                123 Street Name, City, Country
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6} lg={3}>
          <Card className="text-center h-100">
            <Card.Body>
              <FaInstagram size={40} className="mb-3" style={{ color: '#e1306c' }} />
              <Card.Title>Instagram</Card.Title>
              <Card.Text>
                <a href="https://www.instagram.com/yourhandle" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                  @yourhandle
                </a>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
