// src/components/About.js

import React from 'react';
import { Container, Row, Col, Image, Card } from 'react-bootstrap';

const About = () => {
  return (
    <Container className="mt-5" id='about'>
      <h2 className="text-center mb-5">About the Owner & Property</h2>
      
      <Row className="align-items-center mb-5">
        {/* Owner's Section */}
        <Col md={6}>
          <Image 
            src="https://via.placeholder.com/300x300" 
            roundedCircle 
            fluid 
            className="mb-4" 
            alt="Owner" 
          />
        </Col>
        <Col md={6}>
          <Card className="border-0">
            <Card.Body>
              <Card.Title className="mb-3">Meet the Owner</Card.Title>
              <Card.Text>

              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default About;
