// src/components/Carousel.js

import React from 'react';
import { Carousel } from 'react-bootstrap';

const MyCarousel = () => {
  const carouselStyle = {
    height: '100vh', // Full height of the viewport
  };

  const itemStyle = {
    height: 'calc(100vh - 56px)', // Adjust for navbar height (56px is default)
  };

  const imgStyle = {
    objectFit: 'cover', // Ensures the image covers the carousel item
    height: '100%', // Full height of the carousel item
  };

  return (
    <Carousel style={carouselStyle} id='home'>
      <Carousel.Item style={itemStyle}>
        <img
          style={imgStyle}
          className="d-block w-100"
          src="images/img1.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>Property Image 1</h3>
          <p>Description of the first property image.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item style={itemStyle}>
        <img
          style={imgStyle}
          className="d-block w-100"
          src="images/img2.jpg"
          alt="Second slide"
        />
        <Carousel.Caption>
          <h3>Property Image 2</h3>
          <p>Description of the second property image.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item style={itemStyle}>
        <img
          style={imgStyle}
          className="d-block w-100"
          src="images/img3.jpg"
          alt="Third slide"
        />
        <Carousel.Caption>
          <h3>Property Image 3</h3>
          <p>Description of the third property image.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default MyCarousel;
